import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connectMACC } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import { Network, Alchemy } from "alchemy-sdk";
import '../styles/style.css'

require('dotenv').config();

function Mutants() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  // eslint-disable-next-line
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("");
  const [apeSelection, setApeSelection] = useState(null);
  const [mintingNft, setMintingNft] = useState(false);
  const [maccRankSelection, setMaccRankSelection] = useState(null);
  const [maccRankToShow, setMaccRankToShow] = useState(null);
  const [rankMaccImageUrl, setMaccRankImageUrl] = useState("");
  const legendaries = ["0","1","2","3","4","5","6","7","8","9","156","576","1713","2976","3023","3622","3767","3867"];
  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
    network: Network.ETH_MAINNET
  };
  const alchemy = new Alchemy(settings);

  async function getProof(address, wlType) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ address: address, wlType: wlType})
    };
    const response = await fetch(process.env.REACT_APP_BASE_API_URL + '/api/proof', requestOptions);
    const result = await response.json();
    return result;
  }

  function setRankStateValues(token) {
    setMaccRankUrl(token);
    setMaccRankSelection(token);
    setMaccRank(token);
  }

  async function setMaccRank(token_id) {
    try {
      await alchemy.nft.getOwnersForNft(process.env.REACT_APP_MACC_ADDRESS, token_id)
    }
    catch { 
      setMaccRankToShow('');
      return 
    }
    if (token_id) {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };
      const response = await fetch(process.env.REACT_APP_BASE_API_URL + '/api/macc/ranks/' + token_id, requestOptions);
      const result = await response.json();
      let rank = result['rank'];
      setMaccRankToShow(rank);
    }
    else { setMaccRankToShow(null); }
  }

  async function setMaccRankUrl(token_id) {
    if (token_id) {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };
      const response = await fetch(process.env.REACT_APP_BASE_API_URL + '/api/metadata/' + token_id, requestOptions);
      const result = await response.json();
      let imageUrl = result['image_url'];
      setMaccRankImageUrl(imageUrl);
    }
  }

  function isPositiveInteger(n) {
    return n >>> 0 === parseFloat(n);
  }

  function imageToShow() {
    if (!maccRankSelection) {
      return (
        <div className="my-auto col-lg-4 col-12 offset-lg-1">
          <img className="img-fluid w-100" style={{borderRadius: '5px'}} src={'https://ipfs.io/ipfs/Qme4RRP6Q5iWmjnwoqiY2xRdx9fc1cPdht9CA9JYQg8JEH'} alt="mystery token" />
          </div>
        )
    }
    if (maccRankSelection && isPositiveInteger(maccRankSelection) && maccRankToShow && rankMaccImageUrl) {
      return (
        <div className="my-auto col-lg-4 col-12 offset-lg-1">
          <div className="imageItem">
        <img className="img-fluid w-100" style={{borderRadius: '5px'}} src={rankMaccImageUrl} alt='MACC ' />
        <span className="kitten-caption">{`Rank #${maccRankToShow}`}</span>
        </div>
        </div>
      )
    }
    else {
    return (
    <div className="my-auto col-lg-4 col-12 offset-lg-1">
      <img className="img-fluid w-100" style={{borderRadius: '5px'}} src={'https://ipfs.io/ipfs/Qme4RRP6Q5iWmjnwoqiY2xRdx9fc1cPdht9CA9JYQg8JEH'} alt="mystery token" />
      </div>
    )
  }
  }

  async function claimToken(address, token) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ address: address, token: token.toString()})
    };
    const response = await fetch(process.env.REACT_APP_BASE_API_URL + '/api/claim_token', requestOptions);
    const result = await response;
    return result
  }

  const mintMutant = async (serumId=null, apeId=null, numMints=null) => {
    if (data.saleFreeWhitelistActive) {
      freeWhiteListMint(numMints)
    }
    else if (data.saleWhitelistActive) {
      whiteListMint(numMints)
    }
    else if (data.publicSaleActive) {
      dutchAuctionMint(numMints)
    }
    else if (data.serumMutationActive) {
      mutationCaller(serumId, apeId)
    }
  };

  const maccLabels = () => {
    if (data.saleFreeWhitelistActive) {
      return (
        {
          'title': 'MINT A MUTANT',
          'title_two': 'Free Whitelist Minting Status: ',
          'status': 'Open',
          'subTitle': 'Connect your wallet to mint a MACC.',
          'connectedSubTitle': `Mutant Grandpas remaining: ${5000 - data.apesMinted}`,
          'connectedSubTitleTwo': ''
        }
      )
    }
    else if (data.saleWhitelistActive) {
     return (
      {
        'title': 'MINT A MUTANT',
        'title_two': 'Whitelist Minting Status: ',
        'status': 'Open',
        'subTitle': 'Connect your wallet to mint a MACC.',
        'connectedSubTitle': `Mutant Grandpas remaining: ${5000 - data.apesMinted}`,
        'connectedSubTitleTwo': ''
      }
     )
    }
    else if (data.publicSaleActive) {
      let remainingTime = new Date(data.remainingSaleTime * 1000).toISOString().substr(11, 8);
      return (
        {
          'title': 'MINT A MUTANT',
          'title_two': 'Public Dutch Auction Status: ',
          'status': 'Open',
          'subTitle': 'Connect your wallet to mint a MACC.',
          'connectedSubTitle': `Mutant Grandpas remaining: ${5000 - data.apesMinted}`,
          'connectedSubTitleTwo': `Price: ${((data.currentPrice/1000000000000000000)).toFixed(3)}Ξ | Time remaining: ${remainingTime}`
        }
      )
    }
    else if (data.serumMutationActive) {
      return (
        {
          'title': `MUTATE A GRANDPA APE`,
          'title_two': '',
          'status': '',
          'subTitle': 'Connect your wallet to mutate a GACC.',
          'connectedSubTitle': '',
          'connectedSubTitleTwo': ''
        }
      )
    }
    else {
      return({})
    }
  };

  const processErrorMessage = (errorMessage) => {
    const endIndex = errorMessage.message.search('{')
    if (endIndex === -1) {
      return('Insufficient Funds to Mint.')
    } else {
      let err_message = errorMessage.message.substring(0, endIndex)
      let execution = 'execution reverted: '
      let executionIndex = err_message.indexOf(execution)
      if (executionIndex === -1) {
        return(err_message);
      }
      else {
        let cleaned_error = err_message.slice(executionIndex + execution.length)
        return(cleaned_error);
      }
    }
  }

  const freeWhiteListMint = async (numMints) => {
    setFeedback(`Minting your MACC...`);
    setMintingNft(true);
    let wlFreeProof = await getProof(blockchain.account, 'FREE');
    let wlFreeMultiProof = await getProof(blockchain.account, 'FREE_MULTI');
    blockchain.smartContract.methods
      .mintFreeWhitelist(numMints, wlFreeProof, wlFreeMultiProof)
      .call({
        to: process.env.REACT_APP_MACC_ADDRESS,
        from: blockchain.account
      })
      .then(() => {
        blockchain.smartContract.methods
        .mintFreeWhitelist(numMints, wlFreeProof, wlFreeMultiProof)
        .send({ 
          to: process.env.REACT_APP_MACC_ADDRESS,
          from: blockchain.account
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `Congratulations and welcome to the Mutant Ape Country Club!`
          );
          setMintingNft(false);
          dispatch(fetchData(blockchain.account));
        })
        .catch(err => {
          const endIndex = err.message.search('{')
          setFeedback(err.message.substring(0, endIndex));
          setMintingNft(false);
        });
      })
      .catch(err => {
        setFeedback(processErrorMessage(err))
        setMintingNft(false);
      });
  }

  const whiteListMint = async (numMints) => {
    let wlProof = await getProof(blockchain.account, 'WL');
    let wlMultiProof = await getProof(blockchain.account, 'MULTI');
    let cost = data.wlPrice;
    cost = cost * numMints
    let totalCostWei = String(cost);
    setFeedback(`Minting your MACC...`);
    setMintingNft(true);
    blockchain.smartContract.methods
      .mintWhitelist(numMints, wlProof, wlMultiProof)
      .call({
        to: process.env.REACT_APP_MACC_ADDRESS,
        from: blockchain.account,
        value: totalCostWei
      })
      .then(() => {
        blockchain.smartContract.methods
        .mintWhitelist(numMints, wlProof, wlMultiProof)
        .send({ 
          to: process.env.REACT_APP_MACC_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `Congratulations and welcome to the Mutant Ape Country Club!`
          );
          setMintingNft(false);
          dispatch(fetchData(blockchain.account));
        })
        .catch(err => {
          const endIndex = err.message.search('{')
          setFeedback(err.message.substring(0, endIndex));
          setMintingNft(false);
        });
      })
      .catch(err => {
        setFeedback(processErrorMessage(err))
        setMintingNft(false);
      });
  }

  const dutchAuctionMint = async (numMints) => {
    let cost = await blockchain.smartContract.methods.getMintPrice().call()
    cost = cost * numMints
    let buffer = 20000
    let totalCostWei = String(cost + buffer);
    setFeedback(`Minting your MACC...`);
    setMintingNft(true);
    blockchain.smartContract.methods
      .mintMutants(numMints)
      .call({
        to: process.env.REACT_APP_MACC_ADDRESS,
        from: blockchain.account,
        value: totalCostWei
      })
      .then(() => {
        blockchain.smartContract.methods
        .mintMutants(numMints)
        .send({ 
          to: process.env.REACT_APP_MACC_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `Congratulations and welcome to the Mutant Ape Country Club!`
          );
          setMintingNft(false);
          dispatch(fetchData(blockchain.account));
        })
        .catch(err => {
          const endIndex = err.message.search('{')
          setFeedback(err.message.substring(0, endIndex));
          setMintingNft(false);
        });
      })
      .catch(err => {
        let prettyCost = ((cost/1000000000000000000)).toFixed(3);
        let priceInfo = ` The price to mint ${numMints} mutants is ${prettyCost}ETH.`
        setFeedback(processErrorMessage(err)+priceInfo)
        setMintingNft(false);
      });
  }

  const mutationCaller = (serumId, apeId) => {

    if (!serumId && legendaries.includes(apeId)) {
      mutateLegendary(apeId)
    }
    else {
      mutateGrandpa(serumId, apeId)
    }
  }

  const mutateGrandpa = async (serumId, apeId) => {
    setFeedback(`Mutating your GACC...`);
    setMintingNft(true);
    let mutantId = (parseInt(apeId) * 2) + parseInt(serumId) + 4999
    blockchain.smartContract.methods
      .mutateApeWithSerum(serumId, apeId)
      .call({
        to: process.env.REACT_APP_MACC_ADDRESS,
        from: blockchain.account
      })
      .then(() => {
        blockchain.smartContract.methods
        .mutateApeWithSerum(serumId, apeId)
        .send({ 
          to: process.env.REACT_APP_MACC_ADDRESS,
          from: blockchain.account
        })
        .then((receipt) => {
          console.log(receipt);
          claimToken(blockchain.account, mutantId);
          setFeedback(
            `Congratulations on mutating your Grandpa! Your Mutant's ID is ${mutantId}.`
          );
          setMintingNft(false);
          dispatch(fetchData(blockchain.account));
        })
        .catch(err => {
          const endIndex = err.message.search('{')
          setFeedback(err.message.substring(0, endIndex));
          setMintingNft(false);
        });
      })
      .catch(err => {
        setFeedback(processErrorMessage(err))
        setMintingNft(false);
      });
  }

  const mutateLegendary = async (apeId) => {
    setFeedback(`Mutating your Legendary GACC...`);
    setMintingNft(true);
    let mutantId = (parseInt(apeId) * 2) + 1 + 4999
    blockchain.smartContract.methods
      .mutateApeWithoutSerum(apeId)
      .call({
        to: process.env.REACT_APP_MACC_ADDRESS,
        from: blockchain.account
      })
      .then(() => {
        blockchain.smartContract.methods
        .mutateApeWithoutSerum(apeId)
        .send({ 
          to: process.env.REACT_APP_MACC_ADDRESS,
          from: blockchain.account
        })
        .then((receipt) => {
          console.log(receipt);
          claimToken(blockchain.account, mutantId);
          setFeedback(
            `Congratulations on mutating your legendary Grandpa! Your Mutant's ID is ${mutantId}.`
          );
          setMintingNft(false);
          dispatch(fetchData(blockchain.account));
        })
        .catch(err => {
          const endIndex = err.message.search('{')
          setFeedback(err.message.substring(0, endIndex));
          setMintingNft(false);
        });
      })
      .catch(err => {
        setFeedback(processErrorMessage(err))
        setMintingNft(false);
      });
  }

  const titleText = () => {
    return (
      <div className="d-flex justify-content-center">
        {(blockchain.account === "" || blockchain.smartContract === null) ? (
        <p className="common-p mint-subtitle">{maccLabels()['subTitle']}</p>): (
          <p className="common-p mint-subtitle">{maccLabels()['connectedSubTitle']}</p>
        )}
        </div>
    )
  }

  function updateTextInput(val) {
    document.getElementById('textLabel').value=val; 
  }

  const connectAndMintButton = () => {
    if (blockchain.account === "" || blockchain.smartContract === null) {
      return (
        <div className="d-flex justify-content-center"><button 
        className="bayc-button mint-button" 
        type="button"
        onClick={(e) => {
          e.preventDefault();
          dispatch(connectMACC());
          setFeedback(data.errorMsg);
          getData();
        }}
        >
          CONNECT WALLET
        </button></div>
      )
    }
    else if (data.saleWhitelistActive) {
      return (
        <div className="d-flex justify-content-center">
        <form>
          <div className="form-group">
            <input type="range" className="form-range" defaultValue="1" min="1" max="5" id="mintQuantity" onChange={(e) => updateTextInput(e.target.value)}/>
            <input type="text" id="textLabel" defaultValue="1" className="mint-input-read" readOnly></input>
          </div>
          <button type="submit" className="bayc-button mint-button" disabled={mintingNft ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              mintMutant(null, null, document.getElementById("mintQuantity").value);
              getData();
            }}>Mint</button>
        </form>
      </div>
      )
    }
    else if (data.saleFreeWhitelistActive) {
      return (
        <div className="d-flex justify-content-center">
        <form>
          <div className="form-group">
            <input type="range" className="form-range" defaultValue="1" min="1" max="5" id="mintQuantity" onChange={(e) => updateTextInput(e.target.value)}/>
            <input type="text" id="textLabel" defaultValue="1" className="mint-input-read" readOnly></input>
          </div>
          <button type="submit" className="bayc-button mint-button" disabled={mintingNft ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              mintMutant(null, null, document.getElementById("mintQuantity").value);
              getData();
            }}>Mint</button>
        </form>
      </div>
      )
    }
    else if (data.publicSaleActive) {
      return (
      <div className="d-flex justify-content-center">
        <form>
          <div className="form-group">
            <div className="mint-subtitle">{maccLabels()['connectedSubTitleTwo']}</div>
            <input type="range" className="form-range mint-input" defaultValue="1" min="1" max="20" id="mintQuantity" onChange={(e) => updateTextInput(e.target.value)}/>
            <input type="text"  className="mint-input-read" id="textLabel" defaultValue="1" readOnly></input>
          </div>
          <button type="submit" className="bayc-button mint-button" disabled={mintingNft ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              mintMutant(null, null, document.getElementById("mintQuantity").value);
              getData();
            }}>Mint</button>
        </form>
      </div>
      )
    }
    else if (data.serumMutationActive) {
      return (
      <div className="d-flex justify-content-center">
        <form>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1 mint-subtitle">Enter Ape ID to Mutate</label>
            <input className="form-control bayc-button mint-input" name='apeId' id='apeId' onChange={(e) => setApeSelection(e.target.value)}></input>
          </div>
          {!legendaries.includes(apeSelection) && (
              <div className="form-group">
              <label htmlFor="exampleInputPassword1">Select Serum</label>
              <select className="form-control bayc-button serum-mutate-select" id='serumId'>
                <option value="1" name='serumId'>M1 Serum</option>
                <option value="2" name='serumId'>M2 Serum</option>
                <option value="69" name='serumId'>M3 Serum</option>
              </select>
            </div>
          )}
          <button type="submit" className="btn btn-primary bayc-button " disabled={mintingNft ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              if (document.getElementById("serumId")) {
                mintMutant(document.getElementById("serumId").value, document.getElementById("apeId").value);
              }
              else {
                mintMutant(null, document.getElementById("apeId").value);
              }
              getData();
            }}>Mutate</button>
        </form>
      </div>
      )
    }
    else {
      return (
      <div className="d-flex justify-content-center mint-title">
       MINT SOLD OUT!
      </div>
      )
    }
  }

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData());
    }
  };

  useEffect(() => {
  }, [feedback]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [blockchain.account]);

  return (
    <div>
      <div id="root">
        <div className="app">
        <nav id="nav" className="navbar navbar-expand-md navbar-light">
          <a href="/" id="bayc-brand" className="navbar-brand"><img src={process.env.PUBLIC_URL + '/assets/images/GACC_WHITE_2.png'} className="d-inline-block align-top" alt="gacc logo" width="auto" height="70px" /></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="" role="button" ><i className="fa fa-bars" aria-hidden="true" style={{color:"#ffffff"}}></i></span></button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <div className="navbar-nav" id="nav-bar">
              <a id="nav-link" title="BUY A MACC" href="/macc#buy-a-macc" className="nav-link">BUY A MACC</a>
              <a id="nav-link" title="ROADMAP" href="/#roadmap" className="nav-link">ROADMAP</a>
              <a id="nav-link" title="TEAM" href="/#team" className="nav-link">TEAM</a>
              <div className="nav-item dropdown" style={{color: '#f9edcd'}}>
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{color: 'white'}}>MEMBERS</a>
                <div aria-labelledby="nav-dropdown" className="dropdown-menu home-dropdown" style={{margin: '0px', color: 'white', backgroundColor: 'black'}}>
                    <a id="nav-link-active" title="MACC" href="/macc" className="dropdown-item active"  style={{color: 'white', backgroundColor: 'black'}}>MACC</a>
                    <a id="nav-link" title="Kitten Club" href="/kitten-club" className="dropdown-item"  style={{color: 'white', backgroundColor: 'black'}}>KITTEN CLUB</a>
                    <a id="nav-link" title="MERCH" href="/#merch" className="dropdown-item"  style={{color: 'white', backgroundColor: 'black'}}>MERCH</a>
                </div>
                </div>
              <div className="nav-item dropdown" style={{color: '#f9edcd'}}>
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{color: 'white'}}>MARKETPLACES</a>
                    <div aria-labelledby="nav-dropdown" className="dropdown-menu home-dropdown" style={{margin: '0px', color: 'white', backgroundColor: 'black'}}>
                        <a id="nav-link" title="GACC" href="/gacc-marketplace" className="dropdown-item" style={{color: 'white', backgroundColor: 'black'}}>GACC</a>
                        <a id="nav-link" title="SERUMS" href="/serum-marketplace" className="dropdown-item" style={{color: 'white', backgroundColor: 'black'}}>SERUMS</a>
                        <a id="nav-link" title="MACC" href="/macc-marketplace" className="dropdown-item" style={{color: 'white', backgroundColor: 'black'}}>MACC</a>
                        
                    </div>
                </div>
              </div>
              <div className="navbar-nav" id="nav-social">
              <a href="https://discord.gg/8uuhkZ2TA2">
              <i className="fa fa-discord-alt social-icon pr-lg-0" />
              </a>
              <a href="https://twitter.com/GrandpaApeCC">
              <i className="fa fa-twitter social-icon pr-lg-0" />
              </a>
              <a href="https://www.instagram.com/grandpaapecountryclubofficial">
              <i className="fa fa-instagram social-icon pr-lg-0" />
              </a>
          </div>
          </div>
      </nav>
          <div>
            <div style={{transition: 'opacity 400ms ease 0s, transform 400ms ease 0s', transform: 'none', opacity: 1}}>
              <div className="common-container">
                <div className="mb-4 mb-lg-5 container">
                  <div className="row">
                    <div className="px-0 col-12">
                      <img src={process.env.PUBLIC_URL + '/assets/images/MACC_COVER.png'} className="img-fluid px-0" useMap="#mutant" alt=""/>
                    </div>
                  </div>
                </div>
                <hr className="gray-line mb-5" />
                  <div>
                    <div style={{transition: 'opacity 400ms ease 0s, transform 400ms ease 0s', transform: 'none', opacity: 1}}>
                      <div className="mb-5  row">
                        <div className="col">
                          <div className="d-flex justify-content-center w-100 col-12">
                            <div className="MuiPaper-root MuiCard-root jss12 MuiPaper-outlined MuiPaper-rounded" style={{opacity: 1, transform: 'none', transition: 'opacity 291ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 194ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'}}>
                              <div className="MuiCardContent-root">
                                <h2 className="d-flex justify-content-center common-sub-title mint-title">{maccLabels()['title']}</h2>
                                <hr className="black-line" /><center>
                                {titleText()}
                                {connectAndMintButton()}</center>
                                <br></br>
                                <div className="mint-feedback">{feedback}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                <hr className="gray-line mb-5" />
                <div className="px-4 mt-md-4 container">
                  <div className="mb-5  row">
                    <div className="mb-5 mb-lg-0 col-lg-7 col-12">
                      <h1 className="common-title mb-3">WELCOME TO THE<br />MUTANT APE COUNTRY CLUB</h1>
                      <p className="common-p  text-justify">The Mutant Ape Country Club ("MACC") is a collection of up to 10,000 Mutant Ape NFTs that can only be created by exposing an existing Grandpa Ape to a vial of MUTANT SERUM or by minting a Mutant Ape in the public sale.</p>
                      <p className="common-p text-justify">The MACC is a way to reward our grandpa ape holders with an entirely new NFT, a “mutant” version of their grandpa, while also allowing newcomers into the GACC NFT blockchain ecosystem at a lower tier of membership. Mutants represent the final tier of membership; everything going forward occurs with the intention of accruing utility and member’s-only benefits to Grandpa Apes foremost, but also Mutants, and to a lesser extent, Grandpa Apes with GAKC companions.</p>
                    </div>
                    <div className="my-lg-auto col-lg-4 col-12 offset-lg-1">
                      <div className="common-container">
                        <div className="row">
                          <div className="pb-2 pr-2 col-6">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/images/ASTRONAUT.jpg'} alt="mutant-1" aria-label="mutant-1" style={{borderRadius: '5px'}} />
                          </div>
                          <div className="pb-2 pl-2 col-6">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/images/SUSHI.jpg'} alt="mutant-2" aria-label="mutant-2" style={{borderRadius: '5px'}} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="pt-2 pr-2 col-6">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/images/RAINBOW_GRILL.jpg'} alt="mutant-3" aria-label="mutant-3" style={{borderRadius: '5px'}} />
                          </div>
                          <div className="pt-2 pl-2 col-6">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/images/deathbot.jpg'} aria-label="mutant-4" style={{borderRadius: '5px'}} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-5 row">
                      <div className="mb-4 mb-lg-0 col-lg-7 col-12">
                      <h2 className="d-flex common-sub-title font-italic mb-2 bayc-color">OFFICIAL RARITY</h2>
                        <p className="common-p" >Each Mutant Grandpa is unique and programmatically generated from an impossible amount of traits, including expression, headwear, clothing, and more. All mutant grandpas are insane, but some are technically rarer than others.<br /><br />Use the following tool to lookup the official rarity ranking of a MACC. Official rarity is also available in the Discord bot. Remember, beauty is in the eye of the beholder.</p>
                        <form>
                            <div className="form-group">
                            <label for="staticEmail2" className="common-p mb-2"  style={{fontWeight: 'bold'}}>Lookup Rarity</label>
                        <input className="form-control" name='apeId' id='apeId' placeholder="1" style={{textAlign: 'center'}} onChange={(e) => setRankStateValues(e.target.value)}></input>
                        </div>
                          </form>
                      </div>
                      {imageToShow()}
                    </div>
                  <div className="mb-5 row">
                      <div className="col">
                        <div id="buy-a-macc" className="buy-token-container">
                          <div className="bayc-bg p-4 m-auto row">
                            <div className="m-auto col-lg-3 col-12">
                              <h3 className="buy-ape-title">BUY A MACC</h3>
                            </div>
                            <div className="m-auto col-lg-4 col-12 offset-lg-1">
                              <p className="common-p mb-lg-0">To get your Mutant Grandpa Ape, check out the collection on our marketplace, or mutate a GACC using a serum.</p>
                            </div>
                            <div className="m-auto col-lg-2 col-12 offset-lg-1">
                              <a href="/macc-marketplace"><button className="bayc-button mint" type="button">VISIT THE MARKETPLACE</button></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  <hr className="gray-line mb-5" />
                  <div className="mb-5  row">
                    <div className="col-12">
                      <h2 className="common-title mb-3">SERUMS 
                        <span className="bayc-color"> (FOR GACC MEMBERS)</span>
                      </h2>
                    </div>
                    <div className="mb-4 mb-lg-5 col-lg-7 col-12">
                      <p className="common-p">One way to create a MACC is when a Grandpa Ape ingests a vial of mutant serum. There are three tiers of mutant serum vials: M1, M2, and Mega Mutant (M3). A snapshot of all Grandpa Ape token holders was taken at 5pm PST on 2/28/22, and all mutant serums that will exist have been airdropped at a random distribution to corresponding wallets.</p>
                      <p className="common-p">If a Grandpa Ape ingests an M1 or M2 serum, the resulting MUTANT will retain traits of the original ape.</p>
                      <p className="common-p mb-0">If a Grandpa Ape ingests an M3 serum? Who knows...</p>
                    </div>
                    <div className="mb-5 mb-lg-0 col-lg-4 col-12 offset-lg-1">
                      <p className="common-sub-p font-italic mb-0">
                        <span className="bayc-color bold-text">NOTE:</span> Serum vials are burned upon use, and a Grandpa Ape can only ingest a serum of a given vial-type once. This means that any given Grandpa Ape can be exposed to an M1, M2, or M3 vial, resulting in three different mutations of that Grandpa Ape. Vials are nasty stuff however. An ape will never ingest a vial of a given type after it has consumed one before.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 order-lg-2">
                      <div className="row">
                        <div className="col-lg-6 col-6 order-lg-2 offset-lg-0 offset-3">
                          <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/images/kuchuya.jpg'} alt="" style={{borderRadius: '5px'}} />
                          <p className="mt-2 mb-0 text-center">Kuchuya</p>
                        </div>
                        <div className="my-lg-3 d-lg-flex justify-content-center align-items-center col-lg-3 col-3 offset-lg-0 offset-2">
                          <div className="mb-4 m-lg-4 sw">
                            <span className="m_scroll_arrows_down first mx-auto" />
                            <span className="m_scroll_arrows_down second mx-auto" />
                            <span className="m_scroll_arrows_down third mx-auto" />
                          </div>
                        </div>
                        <div className="my-lg-3 d-lg-flex justify-content-center align-items-center col-lg-3 col-3 order-lg-3 offset-lg-0 offset-2">
                          <div className="mb-4 m-lg-4 se">
                            <span className="m_scroll_arrows_down first mx-auto" />
                            <span className="m_scroll_arrows_down second mx-auto" />
                            <span className="m_scroll_arrows_down third mx-auto" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-6 order-lg-1">
                      <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/images/kuchuya_m1.png'} alt="" style={{borderRadius: '5px'}} />
                      <p className="mt-2 mb-0 text-center">M1 Kuchuya</p>
                    </div>
                    <div className="col-lg-3 col-6 order-lg-3">
                      <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/images/kuchuya_m2.png'} alt="" style={{borderRadius: '5px'}} />
                      <p className="mt-2 mb-0 text-center">M2 Kuchuya</p>
                    </div>
                  </div>
                  <hr className="gray-line mb-5" />
                  <div className="mb-5  row">
                    <div className="mb-5 mb-lg-0 col-lg-7 col-12 order-lg-last">
                      <h2 className="common-title mb-3">DISTRIBUTION &amp; PRICING</h2>
                      <p className="common-p text-justify">A total of 5,000 Mutant Serums have been airdropped to all GACC token holders.</p>
                      <p className="common-p text-justify">2,500 Mutant Apes were reserved for Whitelist Wallets, minted during the Whitelist Minting period at a cost of 0.15 ETH. After the Whitelist Mint period closed, 2,500 more Mutant Apes were available to mint in a public Dutch Auction. The price to mint a Mutant started at 0.5 ETH and gradually reduced to about 0.03 ETH over the course of 6 hours. This was to allow the community to set a fair starting price.</p>
                      <p className="common-p text-justify">Once the public sale has concluded, all Mutant Apes were revealed.</p>
                      <p className="common-p text-justify mb-0">Now that the mutants are revealed, GACC members are able to mutate their apes with serums here on the site.</p>
                    </div>
                    <div className="mb-0 my-lg-auto col-lg-5 col-12 offset-lg-0">
                      <img src={process.env.PUBLIC_URL + '/assets/images/serum.gif'} className="img-fluid rounded" alt="" />
                    </div>
                  </div>
                  <div className="mb-5 row">
                      <div className="col">
                        <div id="buy-a-serum" className="buy-token-container">
                          <div className="bayc-bg p-4 m-auto row">
                            <div className="m-auto col-lg-3 col-12">
                              <h3 className="buy-ape-title">BUY A SERUM</h3>
                            </div>
                            <div className="m-auto col-lg-4 col-12 offset-lg-1">
                              <p className="common-p mb-lg-0">The serum snapshot and airdrop has ended. To get your M1, M2, or Mega Serum, check out the collection on our marketplace.</p>
                            </div>
                            <div className="m-auto col-lg-3 col-12 offset-lg-1"><a href="/serum-marketplace"><button className="bayc-button mint" type="button">VISIT THE MARKETPLACE</button></a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  <hr className="gray-line mb-5" />
                  <div className="mb-5  row">
                    <div>
                      <h2 className="common-title mb-3">MUTATION 
                        <span className="bayc-color"> (FOR GACC MEMBERS)</span>
                      </h2>
                      <p className="common-p text-justify">GACC token holders are be able to apply their serums to their Grandpa Apes. Applying a serum to a Grandpa Ape will burn that serum, and result in a MACC NFT which may retain aspects of the original Grandpa Ape. The Grandpa Ape NFT will not be harmed.
                      </p>
                      <p className="common-p bold-text text-justify">THERE IS NO SET TIME LIMIT FOR APPLYING SERUMS.</p>
                      <p className="common-p text-justify mb-0">Remember, a Grandpa Ape can only be mutated via a vial of a certain type once. We will introduce a method for checking whether a Grandpa Ape has been exposed to a given vial type.</p>
                    </div>
                  </div>
                  <div className="mb-5 row">
                      <div className="col">
                        <div className="d-flex justify-content-center">
                          <p className="common-p text-center text-break mb-0"><span className="bold-text">VERIFIED SMART CONTRACT ADDRESS: </span><a title="0xAD0db7368CDFbd3153F7dfaCA51A78Eeb39F6d71" href="https://etherscan.io/address/0xAD0db7368CDFbd3153F7dfaCA51A78Eeb39F6d71" className="link" style={{color: '#bfc500'}}>0xAD0db7368CDFbd3153F7dfaCA51A78Eeb39F6d71</a></p>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid footer-line">
              <hr className="p-0 line" />
              <div className="row mx-0 footer-padding">
                <div className="col-12 col-lg-4 order-lg-first my-lg-auto">
                </div>
                <div className="col-12 col-lg-4 order-first">
                  <img className="img-fluid footer-logo" src={process.env.PUBLIC_URL + '/assets/images/MACC_LOGO.png'} alt="logo" />
                </div>
                <div className="order-last my-auto text-center col-lg-4 col-sm-12 col-12">
                  <div className="row">
                    <div className="text-lg-right col-sm-12 col-12">
                      <a href="https://discord.gg/8uuhkZ2TA2">
                        <i className="fa fa-discord-alt social-icon pr-lg-0" />
                      </a>
                      <a href="https://twitter.com/GrandpaApeCC">
                        <i className="fa fa-twitter social-icon pr-lg-0" />
                      </a>
                      <a href="https://www.instagram.com/grandpaapecountryclubofficial">
                        <i className="fa fa-instagram social-icon pr-lg-0" />
                      </a>
                    </div>
                    <div className="col-lg-12 col-sm-6 col-6">
                      <p className="copyright text-right">
                        <span className="copy-left">© 2023 Grandpa Ape Country Club</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid m-0 p-0">
              <span className="last-line" />
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Mutants;
